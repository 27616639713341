<script>
import { Blok } from 'src/modules/vsf-storyblok-module/components'

export default {
  name: 'Wysiwyg',
  extends: Blok,
  props: {
    tagFilter: {
      type: Function,
      required: false,
      default: (node, tag, data) => ({ tag, data })
    }
  },
  render: function (createElement) {
    const getNodeTag = (node) => {
      switch (node.type) {
        case 'paragraph':
          return 'p'

        case 'ordered_list':
          return 'ol'

        case 'bullet_list':
          return 'ul'

        case 'list_item':
          return 'li'

        case 'hard_break':
          return 'br'

        case 'horizontal_rule':
          return 'hr'

        case 'heading':
          return 'h' + node.attrs.level

        case 'link':
          return 'a'

        case 'image':
          return 'img'

        case 'blockquote':
          return 'blockquote'

        case 'code_block':
          return 'code'

        default:
          return 'span'
      }
    }

    const renderNodeContent = (node) => {
      let tag = getNodeTag(node)
      let content = node.hasOwnProperty('content') ? node.content.map(item => renderNodeContent(item)) : node.hasOwnProperty('text') ? node.text : null
      let data = {}

      if (node.type === 'blok') {
        return node.attrs.body.map(item => createElement('sb-render', { props: { item } }))
      } else if (node.type === 'text') {
        let link = null

        if (node.hasOwnProperty('marks')) {
          for (let i = 0; i < node.marks.length; i++) {
            const mark = node.marks[i]

            if (mark.type === 'link') {
              link = mark.attrs
            } else {
              if (!data.class) {
                data.class = []
              }

              switch (mark.type) {
                case 'bold':
                  data.class.push('font-bold')
                  break

                case 'italic':
                  data.class.push('italic')
                  break

                case 'strike':
                  data.class.push('line-through')
                  break

                case 'underline':
                  data.class.push('underline')
                  break

                case 'code':
                  data.class.push('code')
                  break

                case 'styled':
                  data.class.push(mark.attrs.class)
                  break
              }
            }
          }
        }

        if (link) {
          if (!data.class) {
            data.class = []
          }

          if (!data.attrs) {
            data.attrs = {}
          }

          if (link.linktype === 'email') {
            tag = 'a'
            data.attrs.href = 'mailto:' + link.href
          } else {
            let resolved = this.$router.resolve(link.href)

            if (resolved.resolved.matched.length > 0) {
              tag = 'router-link'
              if (!data.props) {
                data.props = {}
              }
              data.props.to = resolved.normalizedTo
            } else {
              tag = 'a'
              data.attrs.href = link.href
            }
          }

          data.attrs.target = link.target
        }
      } else if (node.type === 'image') {
        data.attrs = Object.assign({}, data.attrs, node.attrs)
      }

      const filtered = this.tagFilter(node, tag, data)

      return createElement(filtered.tag, filtered.data, content)
    }

    return createElement(
      'div',
      this.item.content && this.item.content.content ? this.item.content.content.map(item => {
        return renderNodeContent(item)
      }) : this.item.content.length ? this.item.content.map(item => {
        return renderNodeContent(item)
      }) : null
    )
  }
}
</script>

<style lang="scss" scoped>
p, h1, h2, h3, h4, h5, h6, ul, ol, img {
  @apply mt-4;

  &:first-child {
    @apply mt-0;
  }
}

ul {
  list-style: disc outside none;
}

h1, h2, h3, h4, h5, h6 {
  @apply text-base leading-none;
}

li {
  @apply ml-8 leading-normal text-base;
}
</style>
