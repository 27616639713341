<template>
  <ul class="border-t border-solid border-grey-6">
    <li v-for="_item in item.items" :key="_item._uid" class="border-b border-solid border-grey-6">
      <router-link
        class="py-7 hover:bg-grey-39 flex items-center justify-between link pr-5"
        :to="localizedRoute(_item.link.url)"
      >
        <div class="link__icon-custom rounded-full text-big text-brown-2 border-solid border-2 border-brown-2 flex justify-center items-center mr-2">
          <i :class="_item.icon" />
        </div>
        <div class="link__description">
          <h3 class="font-serif italic text-lg font-normal leading-h2">{{ _item.title }}</h3>
          <wysiwyg :item="_item.description" />
        </div>
        <div class="link__icon-arrow relative text-right">
          <i class="ss-gizmo ss-navigateright" />
        </div>
      </router-link>
    </li>
  </ul>
</template>

<script>
import { Blok } from 'src/modules/vsf-storyblok-module/components'
import Wysiwyg from '../Blocks/Wysiwyg'

export default {
  name: 'RewardsLinks',
  extends: Blok,
  components: {
    Wysiwyg
  }
}
</script>

<style lang="scss" scoped>
.link {
  transition: background-color .25s ease;
  &:hover {
    .link__icon-arrow {
      right: 5px;

      @screen md {
        right: 10px;
      }
    }
  }
  &__icon-custom {
    flex: 0 0 auto;
    height: 60px;
    width: 60px;

    @screen lg {
      height: 80px;
      width: 80px;
    }
  }
  &__icon-arrow {
    right: 15px;
    transition: right .25s ease;
    width: 80px;

    @screen lg {
      right: 30px;
    }
  }
  &__description {
    @screen sm {
      @apply mx-8;
    }

    @screen lg {
      @apply mx-0;
      max-width: 360px;
    }
  }
}
</style>
